/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:34470273-5255-4c07-9b63-b87e3170a8fa",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4dgcvnN5O",
    "aws_user_pools_web_client_id": "6m72lg12pan4h9oof605sfj0cr",
    "oauth": {},
    "aws_cognito_login_mechanism": [
        "EMAIL",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_appsync_graphqlEndpoint": "https://pgfc2fhlebcknfu7y7epcu5yhm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
